import React from 'react';
import Countdown from 'react-countdown';

export default function CountdownTimer(props: any) {
  const endTime = new Date(props.endTime) || new Date('2022-01-01');
  const Completed = () => (
    <div className='bg-white'>
      <div className='max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8'>
        <div className='text-center'>
          <h2 className='text-base font-semibold text-primary-600 tracking-wide uppercase'>Welcome</h2>
          <p className='mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl'>
            Africa Bridges is coming
          </p>
          <p className='max-w-xl mt-5 mx-auto text-xl text-gray-500'>
            This page will update, if you can still see this check in a bit later.
          </p>
        </div>
      </div>
    </div>
  );

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <Completed />;
    } else {
      // Render a countdown
      return (
        <div className='bg-primary-800'>
          <div className='max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20'>
            <div className='max-w-4xl mx-auto text-center'>
              <h2 className='text-3xl font-extrabold text-white sm:text-4xl'>Coming Soon!</h2>
              <p className='mt-3 text-2xl text-white sm:mt-4'>
                The Africa Bridges Marketplace is in beta and will officially launch on:
              </p>
              <p className='mt-3 text-2xl text-white sm:mt-4'>
                <span className='text-3xl font-bold'>{endTime.toDateString()} EST</span>
              </p>
              <p className='mt-3 text-2xl text-white sm:mt-4'>Subscribe to learn more and become a beta user!</p>
            </div>
            <dl className='mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-4 sm:gap-8'>
              <div className='flex flex-col'>
                <dt className='order-2 mt-2 text-lg leading-6 font-medium text-primary-200'>Days</dt>
                <dd className='order-1 text-5xl font-extrabold text-white'>{days}</dd>
              </div>
              <div className='flex flex-col mt-10 sm:mt-0'>
                <dt className='order-2 mt-2 text-lg leading-6 font-medium text-primary-200'>Hours</dt>
                <dd className='order-1 text-5xl font-extrabold text-white'>{hours}</dd>
              </div>
              <div className='flex flex-col mt-10 sm:mt-0'>
                <dt className='order-2 mt-2 text-lg leading-6 font-medium text-primary-200'>Minutes</dt>
                <dd className='order-1 text-5xl font-extrabold text-white'>{minutes}</dd>
              </div>
              <div className='flex flex-col mt-10 sm:mt-0'>
                <dt className='order-2 mt-2 text-lg leading-6 font-medium text-primary-200'>Seconds</dt>
                <dd className='order-1 text-5xl font-extrabold text-white'>{seconds}</dd>
              </div>
            </dl>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div>
        <Countdown date={endTime} renderer={renderer} />
      </div>
    </>
  );
}
