import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { CountdownTimer, Newsletter } from '../components';
import { getSeo } from '../utils/seo';

const HomePage: FC<any> = () => {
  const Header = () => (
    <div className='relative bg-white'>
      <div className='absolute inset-0'>
        <img className='w-full h-full object-cover' src='ab-header.png' alt='' />
        <div className='absolute inset-0 bg-gray-400 mix-blend-multiply' aria-hidden='true' />
      </div>
      <div className='relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8'>
        <h1 className='text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl'>
          Welcome to Africa Bridges
        </h1>
        <p className='mt-6 text-xl text-white max-w-3xl'>
          Africa Bridges is a marketplace exclusively selling products from producers in Africa.
        </p>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>{getSeo()}</Helmet>
      <Header />
      <CountdownTimer endTime='2025-02-01T00:00:00-0500' />
      <Newsletter />
    </>
  );
};

export default HomePage;
