import { useState } from 'react';
import { Alert } from '.';
import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../supabaseClient';

export default function Newsletter() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);

  const DEFAULT_ERROR_MESSAGE = 'Something went wrong please try again later';

  const resetFormFields = () => {
    setEmail('');
  };

  const handleInput = (e: any) => {
    const text = e.target.value;
    setEmail(text);
  };

  const fetchSubscriber = async (email: string) => {
    try {
      let { data: response, error } = await supabase.from('newsletter_subscribers').select('*').eq('email', email);
      if (error) throw error;
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const postSubscriber = async (email: string) => {
    const genObj = {
      id: uuidv4(),
      email
    };

    try {
      const { data, error } = await supabase.from('newsletter_subscribers').insert([genObj]);
      if (error) throw error;
      return data;
    } catch (error: any) {
      throw error;
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      // if subscriber exits send alert, else create subscriber
      const existingSubscriber = await fetchSubscriber(email);

      if (!existingSubscriber) {
        const newUser = await postSubscriber(email);
        if (!newUser) {
          // error: creating new user
          setStatus('error');
          setError(DEFAULT_ERROR_MESSAGE);
        } else {
          // successful newsletter subscription
          setStatus('success');
          setError('Success! Thank you');
        }
      } else {
        // error: user already exists
        setStatus('warn');
        setError('This email has already subscribed');
      }

      resetFormFields();
    } catch (error: any) {
      console.error(error.message);
      setStatus('error');
      setError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setTimeout(() => {
        setStatus(null);
        setError(null);
      }, 4000);
    }
  };

  return (
    <div className='bg-white'>
      <div className='max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
        <h2 className='inline text-3xl font-extrabold tracking-tight text-gray-900 sm:block sm:text-4xl'>
          Get notified when we’re launching.
        </h2>
        <p className='inline text-3xl font-extrabold tracking-tight text-primary-600 sm:block sm:text-4xl'>
          Sign up for our newsletter.
        </p>
        {error !== null && <Alert status={status}>{error}</Alert>}
        <form onSubmit={handleSubmit} className='mt-8 sm:flex'>
          <label htmlFor='email-address' className='sr-only'>
            Email address
          </label>
          <input
            id='email-address'
            name='email'
            type='email'
            value={email}
            onChange={handleInput}
            autoComplete='email'
            required
            className='w-full px-5 py-3 placeholder-gray-500 focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs border-gray-300 rounded-md'
            placeholder='Enter your email'
          />
          <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0'>
            <button
              type='submit'
              className='w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
            >
              Notify me
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
